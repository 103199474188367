.tm-cell {
  border: 1px solid #b5b1af;
}

.tbody {
  background: #e6e6e6;
}

.wrapper {
    text-align: center;
}
.visual-img {
	width:350px;
	height:350px;
}

#inp {
  text-align: center;
  margin: auto;
  padding-left: 500px;
}

body {
  margin: 0;
  padding: 0;
}

.crop-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
}

.controls {
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  left: 0%;
  width: 100%;
  padding: 0 16px;
  height: 80px;
  display: flex;
  align-items: center;
}

.score {
  font-size: 1vw;
  position: absolute;
  top: 87%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: white;
  padding-right: 5%;
  padding-left: 5%;
}

.accept {
  color: green;
}

.reject {
  color: rgb(219, 21, 21);
}

.faded {
  opacity: 0.5;
}